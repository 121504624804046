import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про Курс | VonderLite з Стасом
			</title>
			<meta name={"description"} content={"Водіння - це не просто навичка, це досвід"} />
			<meta property={"og:title"} content={"Про Курс | VonderLite з Стасом"} />
			<meta property={"og:description"} content={"Водіння - це не просто навичка, це досвід"} />
			<meta property={"og:image"} content={"https://vonderlite.com/photos/background.jpg"} />
			<link rel={"shortcut icon"} href={"https://vonderlite.com/photos/logo.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vonderlite.com/photos/logo.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vonderlite.com/photos/logo.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vonderlite.com/photos/logo.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vonderlite.com/photos/logo.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vonderlite.com/photos/logo.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 30px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="50%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="normal 900 62px/1.2 --fontFamily-sans"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					VonderLite
					<br />
					{" "}- ваш путівник на дорозі
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Завдяки багаторічному досвіду, пристрасті до викладання та унікальному підходу до водіння, VonderLite пропонує унікальну подорож за кермом. Дізнайтеся, що робить Taylor найкращим вибором для багатьох.
	Чому варто обрати VonderLite?
				</Text>
				<Link
					href="/contact-us"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-indigo"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Почати курс
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="50%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Персоналізоване навчання
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ваші сильні сторони, ваш темп, ваша подорож. На відміну від великих автошкіл, в VonderLite кожен урок адаптований до вашого унікального стилю навчання.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Всебічне розуміння
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							У той час як багато хто зосереджується виключно на процесі водіння, я гарантую, що ви зрозумієте "чому" за кожним маневром, що дасть вам глибший зв'язок і впевненість на дорозі.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Цілісний підхід
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Окрім просто навичок, я прищеплюю почуття поваги до дороги, інших водіїв та транспортного засобу, виховуючи не просто водіїв, а відповідальних учасників дорожнього руху.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Що відрізняє мене від інших?
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					На кожному занятті є тільки ви і я. Така цілеспрямована увага забезпечує більш поглиблене та цілеспрямоване навчання, ніж у більшості автошкіл. Роки за кермом і незліченна кількість учнів, яких я навчив, дають мені змогу використовувати багатий досвід на кожному занятті, передбачаючи труднощі та формуючи методи викладання відповідно до них. Нічне водіння часто не береться до уваги на багатьох курсах. Зі мною ви опануєте водіння під місяцем, забезпечивши всебічну підготовку.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://vonderlite.com/photos/2.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://vonderlite.com/photos/3.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});